@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

.pt-8 {
  padding-top: 32px;
}
#faq-wrapper {
  margin-top: 10rem;
  .accordion-item:first-child {
    border-top: 1px solid $grey-10;
  }
  .accordion-header {
    .accordion-button {
      &:focus {
        border-color: none;
        box-shadow: none;
        &:not(.collapsed) {
          color: $black-500;
          background-color: transparent;
          box-shadow: none;
        }
      }
    }
  }
}
