@import "./abstract/variables";
@import "./base/buttons";
@import "./base/form";
@import "./base/typography";
@import "./abstract/mixin.scss";
@import "./base/form";

html {
  font-size: 62.5%;
  box-sizing: border-box;
  -webkit-scroll-behavior:smooth;
  scroll-behavior: smooth;
  @media (max-width: 900px) {
    font-size: 59.5%;
  }
  @media (max-width: 700px) {
    font-size: 55.5%;
  }
}

html::-webkit-scrollbar {
  width: 0.8rem;
}

html::-webkit-scrollbar-track {
  background: transparent;
}
html::-webkit-scrollbar-thumb {
  background: #9d9d9d;
  border-radius: 5rem;
}

*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
}
body {
  font-family: $otito_font_regular;
  color: $grey-300;
}

.container {
  padding-inline: var(--bs-gutter-x, 2rem);
  width: 100%;
  margin-inline: auto;
  @media (max-width: 1000px) {
    padding-inline: 1rem;
  }
  @media (max-width: 600px) {
    padding-inline: var(--bs-gutter-x, 2rem);
  }
  @media (min-width: 576px) {
    max-width: 100%;
  }
  @media (min-width: 1060px) {
    max-width: 1000px;
  }
  @media (min-width: 1100px) {
    max-width: 1089px;
  }
  @media (min-width: 1200px) {
    max-width: 1189px;
  }
  @media (min-width: 1400px) {
    max-width: 1289px;
  }
  @media (min-width: 1700px) {
    max-width: 1500px;
  }
  @media (min-width: 1800px) {
    max-width: 1600px;
  }
}

.general-carousel {
  .btn {
    z-index: 2;
    &::before {
      display: none;
    }
  }
  .btn-prev {
    position: absolute;
    top: -3.9rem;
    left: calc(100% - 104px);
  }
  .btn-next {
    position: absolute;
    top: -3.9rem;
    right: 0;
  }
}

.show > .btn-primary.dropdown-toggle {
  background-color: inherit;
  color: inherit;
}
.dropdown-toggle {
  display: flex;
  align-items: center;
  &::after {
    border: 0;
    line-height: 0;
    content: url("https://api.iconify.design/pepicons/angle-down.svg");
  }
  &.no-toggle {
    &:focus {
      box-shadow: none !important;
    }
    &::after {
      display: none;
    }
  }
}
.dropdown-item {
  &:active {
    background-color: rgba($color: $primary-100, $alpha: 0.5);
  }
}
.react-skeleton-load {
  width: 100% !important;
}

.br-5 {
  border-radius: 0.5rem;
}

.count {
  background-color: $primary-500;
  color: $white-000;
  font-size: 1rem;
  font-weight: 600;
  font-family: $otito_font_semi_bold;
  min-width: 1.9rem;
  height: 1.9rem;
  padding-inline: 0.3rem;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: -0.9rem;
  right: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-fit {
  width: -moz-fit-content;
   width: fit-content;
}
