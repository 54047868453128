@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

.custom-container {
  width: 90%;
  margin: 9rem auto 3rem;

  .back-button {
    font-size: 16px;
    margin: 0 0 30px;
    gap: 10px;

    button {
      background: transparent;
      color: #595959;
      cursor: pointer;
    }
  }

  .products-wrapper {
    _border: 2px solid green;
    gap: 32px;
    flex-direction: column;

    .product-image-nav {
      _border: 2px solid black;
      overflow-x: auto;
      flex-shrink: 0;
      display: flex;
      gap: 20px;
      margin: auto;
      _max-width: 450px;
      width: 100%;

      & .opacity-low {
        opacity: 0.5;
      }

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: grey;
      }

      .image-nav {
        width: 93px;
        height: 109px;
        border-radius: 6px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .active-image-nav {
        border: 1px solid black;
      }

      .dot-name {
        font-size: 12px;
        margin-top: 4px;
        color: #383838;
        text-transform: capitalize;
        font-weight: 700;
      }
    }

    .product-information {
      _border: 2px solid orange;
      flex-direction: column;
    }

    .product-image {
      _border: 2px solid red;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      gap: 32px;
      flex-direction: column;
      align-items: center;

      .product-image-wrapper {
        position: relative;
        width: 100%;
        max-width: 500px;
        _border: 3px solid green;

        figure {
          width: 100%;
          height: 500px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .product-images {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;

          .product-images-tabs {
            list-style-type: none;
            margin: 0;
            padding: 0 0 5px;
            overflow-x: auto;
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;

            li {
              border: 3px solid white;
              width: 40px;
              height: 40px;

              img {
                object-fit: cover;
              }
            }
          }
        }

        .control-btns {
          width: 100%;
          _border: 2px solid blue;
          display: flex;
          justify-content: space-between;

          .control-btn {
            height: 46px;
            width: 46px;
            border-radius: 50%;
            background-color: white;
            position: absolute;
            overflow: hidden;
            top: 50%;

            &:nth-child(1) {
              left: -25px;
            }

            &:nth-child(2) {
              right: -25px;
            }

            button {
              width: 100%;
              height: 100%;
              background-color: transparent;
            }

            .icon-style {
              font-size: 24px;
            }
          }
        }
      }
    }

    .product-details {
      _border: 2px solid blue;
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      .wishlist-wrapper {
        padding: 9px 12px;
        align-items: center;
        background: #f8f8f8;
        width: 160px;
        border-radius: 28px;

        .add-to-wishlist {
          color: #1c1c1c;
          font-size: 14px;
          margin: 0 0 0 5px;
        }
      }

      .product-name {
        font-size: 24px;
        font-weight: 600;
        line-height: 43.52px;
      }

      .condition {
        font-size: 14px;
        font-weight: 600;
      }

      .condition-btn {
        width: 100%;
        max-width: 120px;
        height: 45px;
        background-color: #f8f8f8;
      }

      .active-condition {
        border: 1px solid black;
      }

      .colors {
        flex-wrap: nowrap;
        overflow: auto;
        width: 100%;

        &::-webkit-scrollbar {
          width: 2px;
          height: 2px;
        }
      }

      .color-box {
        width: 34px;
        height: 34px;
        padding: 2px;
        border: 1px solid #c7c7c7;
        overflow: hidden;
        flex-shrink: 0;
      }

      .sizes {
        flex-wrap: nowrap;
        overflow: auto;
        width: 100%;

        &::-webkit-scrollbar {
          width: 2px;
          height: 2px;
        }
      }

      .active-color {
        border: 2px solid black;
      }

      .size-btn {
        width: 50px;
        height: 38px;
        background-color: #f8f8f8;
        flex-shrink: 0;
        border: 1px solid black;
        overflow: hidden;

        button {
          font-weight: 600;
        }
      }

      .active-size-btn {
        border: 2px solid black;
      }
    }
  }
}

@media (min-width: 768px) {
  .custom-container {
    .products-wrapper {
      .product-image {
        width: 70%;

        .product-image-wrapper {
          figure {
            height: 650px;
          }
        }
      }

      .product-details {
        width: 70%;

        .product-name {
          font-size: 32px;
        }

        .condition-btn {
          max-width: 205px;
          height: 81px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .custom-container {
    .products-wrapper {
      flex-direction: column;

      .product-image {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
      }

      .product-information {
        width: 100%;
        flex-direction: row;
      }

      .product-details {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .custom-container {
    .products-wrapper {
      flex-direction: row;
      .product-image-nav {
        _border: 2px solid blue;
        overflow-y: auto;
        justify-content: flex-start;
        flex-direction: column;
        height: 500px;
        width: auto;
        margin: 0;
      }
      .product-image {
        flex-direction: row;
      }

      .product-details {
        // width: 100%;
        // max-width: 500px;
      }
    }
  }
}
