.cart-summary-container {
  background: rgba(209, 209, 209, 0.2);
  padding: 3rem;

  @media (max-width: 50rem) {
    padding: 2rem;
  }
  .image-item {
    img {
      height: 9.4rem;
      width: 100%;
      object-fit: cover;
    }
  }
}
