.filter-container {
  .dropdown-item {
    padding: 0.4rem 1rem;
  }
  .wide-filter-dropdown {
    width: 45rem;
    padding: 1rem;
    @media (max-width: 992px) {
      max-width: max-content;
    }
  }
  .filters-card .dropdown-menu.show {
    height: 44rem;
    overflow: auto;
  }
  .form-check {
    padding-left: 2.3em;
    cursor: pointer;
    .form-check-input {
      font-size: 1.4rem;
    }
    input,
    label {
      cursor: pointer;
    }
  }
}
