@import "assets/styles/abstract/variables";

.loader-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .loader-title {
    color: $black-500;
    font-weight: 800;
    font-size: 0.8em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: 0;
  }

  .loading span {
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    margin: 10px 3px;
    background: rgba($color: $blue, $alpha: 0.6);
    border-radius: 0.6em;
    animation: loading 1s infinite alternate;
  }

  .loading span:nth-of-type(2) {
    background: rgba($color: $error, $alpha: 0.6);
    animation-delay: 0.2s;
  }

  .loading span:nth-of-type(3) {
    background: rgba($color: $blue, $alpha: 0.4);
    animation-delay: 0.4s;
  }

  .loading span:nth-of-type(4) {
    background: $primary-500;
    animation-delay: 0.6s;
  }

  .loading span:nth-of-type(5) {
    background: $primary-300;
    animation-delay: 0.8s;
  }

  .loading span:nth-of-type(6) {
    background: $primary-200;
    animation-delay: 1s;
  }

  .loading span:nth-of-type(7) {
    background: $primary-100;
    animation-delay: 1.2s;
  }

  @keyframes loading {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
