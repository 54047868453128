@import "assets/styles/abstract/variables";

footer {
  margin-top: auto;
  a {
    font-size: 1.4rem;
  }
  .footer-line {
    border-bottom: 0.1rem solid $black-100;
    padding-bottom: 3rem;
  }
}
