@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

#lookbook-grid-wrapper {
  @include img100;
  img {
    height: 100%;
  }
  .lookbook-grid-template {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  }
  .lookbook-banner {
    img {
      min-height: 46rem;
    }
  }

  .lookbook-wrapper {
    cursor: pointer;
    position: relative;
    transition: all ease 0.5s;

    &:hover {
      .lookbook-banner {
        transition: all ease 0.5s;
        img {
          transform: scale(1.1);
        }
      }

      .item-card {
        a {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .lookbook-product-wrapper {
    @media (max-width: 990px) {
      margin-top: 8rem;
    }
  }
}
