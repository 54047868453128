@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

.updateCartModal {
  .modal-header,
  .modal-footer {
    border: none;
  }
  .modal-body {
    .order {
      .itemImage {
        width: 11rem;
        height: 9rem;
        object-fit: cover;
      }

      @include increment;
      .increment-btn {
        height: 4rem;
      }
      .color-box-wrapper {
        border: 0.1rem solid $line-color-200;
        min-width: 5.5rem;
        padding: 1rem;
        height: 4rem;
        font-size: 1.6rem;
        color: $black-500;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: space-around;
      }
      .color-box {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
