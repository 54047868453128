.OurStoryWrapper {
  margin-top: 16rem;
  .headline {
    font-size: 4rem;
    @media (max-width: 600px) {
      font-size: 3rem;
    }
  }
  .story {
    padding-block: 23rem;
    position: relative;
    .chain {
      position: absolute;
      width: 25rem;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    .irukere_lol {
      position: absolute;
      width: 36rem;
      left: 0;
      top: -6rem;
      z-index: -1;
      @media (max-width: 500px) {
        top: -8rem;
      }
    }
  }
}
