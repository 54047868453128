@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

#cart-modal {
  margin-top: 10vh;
  width: 450px;
  height: auto;
  max-width: 100%;
  margin-left: auto;
  visibility: visible;
  @media (min-width: 1000px) {
    margin-right: 60px;
  }
  .btn-close {
    opacity: 0.9;
    background: url("https://api.iconify.design/iconoir/cancel.svg?color=white")
      no-repeat center center / contain;
    background-color: $black-500;
    height: 2rem;
    width: 2rem;
    background-size: 63%;
    @media (min-width: 1000px) {
      margin-top: -3rem;
      margin-right: -4rem;
    }
  }

  .cart-item-body {
    padding-top: 0;
    padding-inline: 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    .icon-pointer {
      cursor: pointer;
    }
    .image-item {
      img {
        height: 9.4rem;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
