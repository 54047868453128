@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

#home-wrapper {
  header {
    background-color: $grey-20;
    #home-banner {
      padding-block: 11rem 7rem;
      h1 {
        font-size: 5.2rem;
        line-height: 6rem;
        color: $black-500;
      }
      .banner-image {
        max-width: 100%;
        height: 40rem;
        object-fit: cover;
      }
    }
  }

  .about-section {
    padding-block: 17vh;
    color: $black-500;
    @media (max-width: 1000px) {
      padding-block: 10vh;
    }
  }
  .culture-wrapper {
    @include img100;
    padding-top: 12rem;
    .culture-layer {
      bottom: 3rem;
    }
    @media (max-width: 1000px) {
      padding-top: 5rem;
    }
  }
  .hiw-wrapper {
    padding-top: 12rem;
    .sub-title {
      padding-bottom: 9rem;
    }
    @media (max-width: 1000px) {
      padding-top: 5rem;
      .sub-title {
        padding-bottom: 1rem;
      }
    }

    %Hiw-absolute {
      position: absolute;
      font-family: $font_ubuntu;
      font-style: normal;
      font-weight: 400;
      font-size: 20rem;
      color: $primary-100;
      left: 3rem;
      bottom: -1rem;
      opacity: 0.3;
      line-height: 1;
      @media (max-width: 992px) {
        font-size: 10rem;
      }
    }
    .hiw1 {
      position: relative;
      &::after {
        content: "1";
        @extend %Hiw-absolute;
      }
    }
    .hiw2 {
      position: relative;
      &::after {
        content: "2";
        @extend %Hiw-absolute;
      }
    }
    .hiw3 {
      position: relative;
      &::after {
        content: "3";
        @extend %Hiw-absolute;
      }
    }
  }
  .botm-wrapper {
    margin-top: 12rem;
    padding-top: 8rem;
    min-height: 65vh;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .btn {
      width: 21rem;
      max-width: 100%;
    }
  }
}
.tcl-wrapper {
  margin-top: 6em;
  .lookbook-banner {
    height: 35rem;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

.empty-best-buy {
  padding: 60px 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .best-buy-text {
    font-size: 20px;
    margin: 10px 0;
  }
}
