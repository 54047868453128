@mixin img100 {
  img {
    object-fit: cover;
    width: 100%;
  }
}

@mixin dropdown-border {
  border: none;
  box-shadow: 0px 20px 50px rgba(5, 4, 4, 0.15) !important;
}

@mixin increment {
  .increment-btn {
    border: 0.1rem solid $line-color-200;
    min-width: 5.5rem;
    padding: 1rem;
    height: 5rem;
    font-size: 1.6rem;
    color: $black-500;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    justify-content: space-around;
  }
}
@mixin cart-progress {
  .cart-progress {
    border-right: 0.1rem solid $primary-200;
    width: 4rem;
    height: 1rem;
    &.cart-progress-br {
      border-radius: 5rem 0rem 0rem 5rem;
    }
    &.cart-progress-bl {
      border-radius: 0rem 5rem 5rem 0rem;
    }
  }
}
