@import "../abstract/variables";
.fw-light {
  font-weight: 300;
  font-family: $otito_font_light;
}
.fw-regular {
  font-weight: 400;
  font-family: $otito_font_regular;
}
.fw-medium {
  font-weight: 500;
  font-family: $otito_font_medium;
}
.fw-semi-bold {
  font-weight: 600;
  font-family: $otito_font_semi_bold;
}
.fw-bold {
  font-weight: 700;
  font-family: $otito_font_bold;
}
.fw-extra-bold {
  font-weight: 800;
  font-family: $otito_font_extra_bold;
}
.click {
  cursor: pointer;
}
.text-xs {
  font-size: 1.2rem !important;
}
.text-sm {
  font-size: 1.4rem !important;
}
.text-base {
  font-size: 1.5rem !important;
}
.text-lg {
  font-size: 1.6rem !important;
}
.text-xl {
  font-size: 1.8rem !important;
}
.text-xxl {
  font-size: 2rem !important;
}
.text-2xl {
  font-size: 2.4rem !important;
}
.text-3xl {
  font-size: 4rem !important;
}
.title {
  font-size: 5.4rem;
  line-height: 5.7rem;
  color: $black-500;
  @media (max-width: 500px) {
    font-size: 4.4rem;
  }
}
.sub-title {
  font-size: 3.5rem;
  color: $black-500;
  font-family: $otito_font_bold;
  font-weight: 700;
}
.xsub-title {
  font-size: 3.2rem;
  color: $black-500;
  font-family: $otito_font_bold;
  font-weight: 700;
}
.line-height-1 {
  line-height: 1;
}
