.filter-container-lookbook {
  .filter-dropdown {
    .wide-dropdown-filter {
      height: 44rem;
      padding: 20px;
      overflow: auto;

      .row-custom {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

.align-brands {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 420px) {
  .filter-container-lookbook {
    .filter-dropdown {
      .wide-dropdown-filter {
        .row-custom {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}
