@import "assets/styles/abstract/variables";

.navbar {
  z-index: 3;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  background-color: $white-000;
  padding-block: 1.3rem;
  @media (max-width: 992px) {
    display: none;
  }

  &.fixed-top {
    .navbar-brand {
      img {
        height: 3rem;
        width: 19rem;
        object-fit: contain;
      }
    }
  }
  .nav-link {
    color: $grey-300 !important;
    padding: 0 1.8rem !important;
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.3s ease;
    font-size: 1.5rem;
    font-weight: 500;
    font-family: $otito_font_medium;
    &:hover,
    &.active {
      opacity: 1;
    }
    &.active {
      font-family: $otito_font_semi_bold;
    }
  }
  .dropdown {
    .nav-link {
      padding: 0.8rem 1.6rem !important;
    }
  }
}
.product-search-wrapper {
  .search-dropdown {
    overflow: hidden;
    border: solid 0.1rem;
    border-color: $grey-10;
    z-index: 3;
    top: 3rem;
    border-radius: 0.5rem;
    position: absolute;
    background-color: $white-000;
    width: 100%;
    a {
      background-color: $white-000;
      transition: all 0.5s ease;
      color: $black-100;
      &:hover {
        background-color: $grey-10;
      }
    }
  }
  .spinner-border {
    position: absolute;
    right: 1.9rem;
  }
  .form-control {
    min-height: 0;
  }
}
