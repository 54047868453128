@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

.brand-img-wrapper {
  margin-block: 6rem 4rem;
  @include img100;
}
.brand-wrapper {
  .brand-letter {
    border: 0.1rem solid $grey-100;
    opacity: 0.8;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.6rem;
    font-family: $otito_font_semi_bold;
    color: $black-500;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    &.big {
      width: 3.5rem;
      height: 3.5rem;
    }
    &:hover {
      color: inherit;
    }
    &.inactive {
      color: $grey-100;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
