@import "../abstract/variables";

.form-control {
  background: $white-000;
  border: 0.1rem solid $line-color-200;
  min-height: 4.5rem;
  font-size: 1.4rem;
  &:focus,
  &:active {
    box-shadow: none !important;
    outline: none !important;
    border: 0.1rem solid $primary-500;
  }

  &::placeholder {
    color: $grey-100;
    font-family: $otito_font_regular;
    font-size: 1.5rem;
  }
}
