@import "assets/styles/abstract/mixin";
@import "assets/styles/abstract/variables";

#new-arrival-wrapper {
  padding-top: 20rem;
  @media (max-width: 1000px) {
    padding-top: 10rem;
  }

  .new-arrival-carousel {
    .btn {
      top: 41%;
      position: absolute;
      &.btn-prev {
        left: 2rem;
      }
    }
    .item-card {
      margin: 1rem;
      a {
        height: 35rem;
      }
    }
  }
}
