.Toastify__toast-container {
  width: 320px;
  font-size: 1.5rem;
}

.Toastify__toast--default {
  background-color: #a0aec0;
  color: #fff;
}

.Toastify__toast--info {
  background-color: #4299e1;
}

.Toastify__toast--success {
  background-color: #48bb78;
}

.Toastify__toast--warning {
  background-color: #ecc94b;
}

.Toastify__toast--error {
  background-color: #f56565;
}
