.lookbook-banner {
  position: relative;
  height: 100%;
  overflow: hidden;
  transition: all ease 0.5s;
  cursor: pointer;

  img {
    transition: all ease 0.5s;
  }
  // &::after {
  //   content: "";
  // background-color: rgba($color: #000000, $alpha: 0.4);
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  // height: 100%;
  //   transition: all ease 0.5s;
  // }

  .lookbook-layer {
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.4);
    transition: all ease 0.5s;

    .mb-custom {
      margin-bottom: 3.5rem;
      width: 100%;
    }
  }
}
