.item-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  &,
  ul {
    list-style: none;
    padding: 0;
  }
  .products {
    width: 33vw;
  }
  .actions,
  .item-price {
    width: 20vw;
  }
  @media (max-width: 992px) {
    display: block;
    .products,
    .actions,
    .item-price {
      width: 100%;
    }
  }
}
