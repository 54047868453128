@import "assets/styles/abstract/variables";

.floating-arrow {
  background: linear-gradient(
    0deg,
    rgba($black-000, 0.52),
    rgba($black-000, 0.52)
  );
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  color: $white-000;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 0.3s;
  &:hover {
    background-color: rgba($black-500, 0.7);
  }
}
