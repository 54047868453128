@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

.order-summary-wrapper {
  .order {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(4rem, auto));
    grid-gap: 5rem;
    align-items: center;
    .itemImage {
      width: 11rem;
      height: 9rem;
      object-fit: cover;
    }
    .sec_1 {
      width: 40rem;
    }
    @media (max-width: 1000px) {
      grid-gap: 2rem;
      .sec_1 {
        width: 25rem;
      }
    }
    @media (max-width: 640px) {
      grid-template-columns: repeat(6, auto);
      grid-gap: 2rem;
      .sec_1 {
        width: fit-content;
        width: -moz-fit-content;
        grid-column: span 3;
      }
      .sec_2 {
        grid-column: span 2;
      }
    }
    @media (max-width: 500px) {
      grid-template-columns: repeat(6, auto);
      grid-gap: 2rem;
      .sec_1 {
        width: fit-content;
        width: -moz-fit-content;
        grid-column: span 3;
      }
      .sec_2 {
        grid-column: span 3;
      }
    }
    @include increment;
    .increment-btn {
      width: 12rem;
      height: 4rem;
    }
    .color-box-wrapper,
    .size-wrapper {
      border: 0.1rem solid $line-color-200;
      min-width: 7rem;
      max-width: 10rem;
      padding: 1rem;
      height: 4rem;
      font-size: 1.6rem;
      color: $black-500;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      justify-content: space-around;
      .color-box {
        width: 2rem;
        height: 2rem;
        // background: $black-500;
      }
    }
    .color-box-wrapper {
      width: 8rem;
    }
  }
}

.grid-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .details-wrapper {
    width: 90%;
    display: grid;
    grid-template-columns: minmax(0, 130px) 1fr;
  }
}
