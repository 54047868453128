@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

.product-wrapper {
  @include increment;
  .price-tag {
    border: 0.1rem solid $line-color-200;
    padding: 1rem;
    min-width: 16rem;
    opacity: 0.8;
    transition: all ease 0.3s;
    cursor: pointer;
    .xsub-title {
      line-height: 1;
      font-size: 3rem;
    }
    &.active {
      opacity: 1;
      border: 0.16rem solid $black-100;
    }
  }
  .sizes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(3rem, auto));
    grid-gap: 2rem;
  }
  .size-number,
  .color-box {
    border: 0.1rem solid $line-color-200;
    min-width: 3rem;
    padding-inline: 0.2rem;
    height: 3rem;
    font-size: 1.4rem;
    font-family: $otito_font_semi_bold;
    color: $black-500;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    &.active {
      border: 0.3rem solid $black-300;
      padding: 0.1rem;
    }
  }
  .color-box {
    padding-inline: 0;
  }
  .accordion-icon {
    width: 4rem;
    height: 4rem;
    background: rgba(209, 209, 209, 0.2);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .p-image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48%, auto));
    grid-gap: 2rem;
    @media (max-width: 1080px) {
      grid-template-columns: repeat(auto-fill, minmax(40%, auto));
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
