@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

.item-flag {
  border-radius: 10px;
  padding: 6px;
  letter-spacing: 1.2px;
  width: fit-content;
  text-transform: capitalize;
  background-color: rgba(119, 0, 195, 0.3);
  color: white;
  width: -moz-fit-content;
}

.cart-flag {
  border-radius: 5px;
  width: fit-content;
  padding: 2px 10px;
  margin: 0 0 0 4px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: rgba(119, 0, 195, 0.3);
  color: white;
  width: -moz-fit-content;
}
