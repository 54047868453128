@import "assets/styles/abstract/variables";

.blk-banner {
  margin-block: 11rem 2rem;
  padding: 3rem 2rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  .progress {
    width: 27rem;
    height: 1.6rem;
    background-color: $primary-100;
    border-radius: 5rem;
    .progress-bar {
      background-color: $primary-500;
    }
  }
  p {
    opacity: 0.8;
  }
}
