@import "assets/styles/abstract/variables";

.account-wrapper {
  .count.order-count {
    top: -1.5rem;
    right: -1.1rem;
  }
  .shared-cart-wrapper {
    margin-top: 7rem;
    .nav-tabs {
      width: fit-content;
      margin-inline: auto;
      border: none;
      column-gap: 1rem;
      .nav-link {
        border: solid 0.2rem #dee2e6;
        min-width: 15rem;
        border-radius: 0.5rem;
        &.active {
          border-color: $primary-200;
        }
      }
    }
  }
}
