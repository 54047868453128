@import "assets/styles/abstract/variables";

.paginator-wrapper {
  display: flex;
  list-style: none;
  //   column-gap: 2rem;
  border: 0.1rem solid $line-color-200;
  width: fit-content;
  padding: 0 0.6rem;
  li {
    min-width: 4rem;
    min-height: 4rem;
    a {
      font-size: 1.4rem;
      color: $black-300;
      font-family: $otito_font_medium;
      cursor: pointer;
      display: block;
      height: 100%;
      width: 100%;
      border-right: 0.1rem solid $line-color-200;
      padding: 1.2rem;
      &[rel="prev"],
      &[rel="next"] {
        font-size: 1.8rem;
        padding: 0.8rem;
      }
    }
    &:last-of-type {
      a {
        border: none;
      }
    }
    &.selected {
      background-color: $primary-500;
      color: $white-000;
    }
  }
}
