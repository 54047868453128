@import "assets/styles/abstract/variables";
@import "assets/styles/abstract/mixin";

.item-card {
  padding: 1rem;
  background: $grey-30;
  position: relative;
  // width: 30rem;
  max-width: 35rem;
  img {
    width: 100%;
  }
  .heart {
    color: $black-200;
  }
  a {
    height: 22.5rem;
    display: block;
    overflow: hidden;
    width: 100%;
    img {
      transition: all 0.5s ease;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  .just-in,
  .right-checkbox {
    padding: 0.7rem 0.9rem;
    background: $primary-100;
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1;
    .form-check-input {
      background-image: url("../../assets/images/checkb0x.svg") !important;
      background-color: $black-500;
      &:checked {
        background-color: $primary-500;
        border-color: $primary-500;
      }
    }
  }
  .just-in {
    top: 1rem;
    right: 1rem;
    padding: 0.5rem 0.7rem;
  }
}
.fav-icon {
  border-radius: 50%;
  transition: all ease 0.3s;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgba($color: $grey-100, $alpha: 0.2);
  }
}
