.menu-icon {
  position: fixed;
  right: 5vw;
  top: 1.5rem;
  padding: 0;
  color: #808080;
  display: none;
  z-index: 6;
  background-color: white;
  box-shadow: 0px 20px 50px rgba(5, 4, 4, 0.15);
  padding: 1rem;
  border-radius: 50%;
  cursor: pointer;
  &:active {
    border: none;
    outline: none;
  }
  @media (max-width: 992px) {
    display: block;
  }
  &.navbar-toggler {
    font-size: 2.25rem;
  }
}
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.8);
  .close {
    display: none;
  }
  &.overlay-isOpen {
    width: 100%;
    z-index: 5;
    .close {
      padding: 0.5rem 1rem;
      color: white;
      cursor: pointer;
      font-size: 4rem;
      right: 0;
      position: absolute;
      display: block;
    }
  }
}
.sidebar {
  transition: 0.4s all ease-in-out;
  overflow-y: auto;
  position: fixed;
  width: 0% !important;
  height: 100%;
  z-index: 20;
  &::-webkit-scrollbar {
    display: none;
  }

  &.sidebar-isOpen {
    width: 60% !important;
    top: 0;
    left: 0;
  }
  .sidebar-wrapper {
    position: absolute;
    background-color: #eff0f2;
    background-size: cover;
    color: #808080 !important;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .side-logo {
      padding: 1rem;
      a {
        color: #808080;
        font-size: 2rem;
        white-space: nowrap;
      }
      img {
        height: 5rem;
        object-fit: contain;
        max-width: 100%;
        width: 11.9rem;
      }
    }
  }

  .side-item {
    padding: 1.2rem;
    border-bottom: 0.2rem solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: bold;
    display: block;
    color: #808080;
    text-decoration: none;
    &.active {
      color: #171e40;
    }
  }

  @media (min-width: 992px) {
    display: none !important;
  }
}

.nav-logo-2 {
  position: absolute;
  left: 3vw;
  top: 1.2rem;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
  img {
    height: 5rem;
    object-fit: contain;
    max-width: 100%;
    width: 14.9rem;
  }
}
